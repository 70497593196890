import React, { useContext } from "react";
import { TopicContext } from "./TopicContext";
import "./Carousel.css";

export default function Carousel() {
  const {
    carouselIndex,
    setCarouselIndex,
    // eslint-disable-next-line
    itemChosen,
    setItemChosen,
    topicType, // eslint-disable-next-line
    setTopicType, // eslint-disable-next-line
    meetArray, // eslint-disable-next-line
    setMeetArray,
    expertise, // eslint-disable-next-line
    setExpertise,
    inspiration, // eslint-disable-next-line
    setInspiration,
    partnerships, // eslint-disable-next-line
    setPartnerships,
  } = useContext(TopicContext);

  const selectedArray =
    topicType === "expertise"
      ? expertise
      : topicType === "inspiration"
        ? inspiration
        : topicType === "partnerships"
          ? partnerships
          : expertise;

  const updateIndex = (newIndex) => {
    setItemChosen((topic) => false);
    setTimeout(() => {
      setItemChosen((topic) => true);
    }, 3000);
    return setCarouselIndex((topic) => newIndex);
  };

  return topicType === "partnerships" ? (
    <div className="carousel">
      <div className="carousel-container partners-carousel">
        <div className="inner">
          <div
            index="1"
            className={`${2 === carouselIndex ? "active" : ""} carousel-item`}
            onClick={() => {
              updateIndex(2);
            }}
          >
            <img
              src={selectedArray.find((obj) => obj.index === 2).image}
              alt="logo"
            />
          </div>
          <div
            index="2"
            className={`${3 === carouselIndex ? "active" : ""} carousel-item`}
            onClick={() => {
              updateIndex(3);
            }}
          >
            <img
              src={selectedArray.find((obj) => obj.index === 3).image}
              alt="logo"
            />
          </div>
          <div
            index="3"
            className={`${4 === carouselIndex ? "active" : ""} carousel-item`}
            onClick={() => {
              updateIndex(4);
            }}
          >
            <img
              src={selectedArray.find((obj) => obj.index === 4).image}
              alt="logo"
            />
          </div>
          <div
            index="4"
            className={`${5 === carouselIndex ? "active" : ""} carousel-item`}
            onClick={() => {
              updateIndex(5);
            }}
          >
            <img
              src={selectedArray.find((obj) => obj.index === 5).image}
              alt="logo"
            />
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="carousel">
      <div className="carousel-container">
        <div className="inner">
          <div
            index="1"
            className={`${1 === carouselIndex ? "active" : ""} carousel-item`}
            onClick={() => {
              updateIndex(1);
            }}
          >
            <img
              src={selectedArray.find((obj) => obj.index === 1).image}
              alt="logo"
            />
          </div>
          <div
            index="2"
            className={`${2 === carouselIndex ? "active" : ""} carousel-item`}
            onClick={() => {
              updateIndex(2);
            }}
          >
            <img
              src={selectedArray.find((obj) => obj.index === 2).image}
              alt="logo"
            />
          </div>
          <div
            index="3"
            className={`${3 === carouselIndex ? "active" : ""} carousel-item`}
            onClick={() => {
              updateIndex(3);
            }}
          >
            <img
              src={selectedArray.find((obj) => obj.index === 3).image}
              alt="logo"
            />
          </div>
          <div
            index="4"
            className={`${4 === carouselIndex ? "active" : ""} carousel-item`}
            onClick={() => {
              updateIndex(4);
            }}
          >
            <img
              src={selectedArray.find((obj) => obj.index === 4).image}
              alt="logo"
            />
          </div>
          <div
            index="5"
            className={`${5 === carouselIndex ? "active" : ""} carousel-item`}
            onClick={() => {
              updateIndex(5);
            }}
          >
            <img
              src={selectedArray.find((obj) => obj.index === 5).image}
              alt="logo"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
