import { React, useContext } from "react";
import { TopicContext } from "./TopicContext";
import "./media-content.css";
// import expertise from "./assets/expertise.json"
// import inspriration from "./assets/inspiration.json"
// import partnerships from "./assets/partnerships.json"

function Media() {
  const {
    topicType,
    // eslint-disable-next-line
    setTopicType,
    itemChosen,
    setItemChosen,
    carouselIndex,
    // eslint-disable-next-line
    setCarouselIndex,
    expertise, // eslint-disable-next-line
    setExpertise,
    inspiration, // eslint-disable-next-line
    setInspiration,
    partnerships, // eslint-disable-next-line
    setPartnerships,
  } = useContext(TopicContext);

  const selectedArray =
    topicType === "expertise"
      ? expertise
      : topicType === "inspiration"
        ? inspiration
        : topicType === "partnerships"
          ? partnerships
          : expertise;

  const handleClick = () => {
    setItemChosen((topic) => true);
  };

  const found = selectedArray.find((obj) => {
    return obj.index === carouselIndex;
  });
  return itemChosen && topicType === "expertise" ? (
    <div className="central-media-container">
      <div className="central-media-content">
        <iframe
          width="100%"
          height="100%"
          src={found.video}
          title="YouTube video player"
          frameborder="0"
        ></iframe>
      </div>
    </div>
  ) : itemChosen && topicType === "inspiration" ? (
    <div className="central-media-container">
      <div className="central-media-content">
        <div className="meet-slide">
          <p className="meet-quote">
            "{found.quote}"
            <br />
            <br />
            -- {found.owner}
            <br />
            {found.title}
          </p>

          <div className="secondary-image">
            <img src={found.image2} alt={found.name} />
          </div>
        </div>
      </div>
    </div>
  ) : itemChosen && topicType === "partnerships" ? (
    <div className="central-media-container">
      <div className="central-media-content">
        <div className="meet-slide">
          <p className="meet-quote">{found.text}</p>
          <div className="secondary-image">
            <img src={found.image2} alt={found.name} />
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div onClick={handleClick} className="central-media-container">
      <div className="central-media-content">
        <h3>{found.name}</h3>
        <br />
        <h4>
          {found.description}
          {found.discipline}
          {found.breed}
        </h4>
        <hr />
      </div>
    </div>
  );
}
export default Media;
